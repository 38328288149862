import "./custom.css";

import Search from "../../../components/search/search";

import Ads from "../../../components/ads/ads";

import { useContext } from "react";

import { SearchContext } from "../../../core/providers/search";

import Pagination from "@material-ui/lab/Pagination";

import { index } from "../../../components/searchBar/searchBar";

const CustomSearchView = function () {
  const { search, setSearch } = useContext(SearchContext);

  const handleChangePageEvent = async function (
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    try {
      const response = await index.search(search.type, {
        hitsPerPage: 5,
        page: value - 1,
        facetFilters: ["delete:false"],
      });

      setSearch({
        ...search,
        hits: response.hits,
        page: response.page,
        pages: response.nbPages,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="custom-view-wrapper">
      <Search />
      {search.hits.map((hit, index) => (
        <Ads key={index} ad={hit} />
      ))}
      <Pagination
        count={search.pages}
        variant="outlined"
        shape="rounded"
        size="large"
        onChange={handleChangePageEvent}
      />
    </div>
  );
};

export default CustomSearchView;
