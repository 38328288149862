import "./ads.css";

import { useContext, useEffect, useRef, useState } from "react";

import { Route, Switch, useParams } from "react-router-dom";

import Firestore from "../../../core/services/firestore";

import Search from "../../../components/search/search";
import Annonce from "../../../components/ads/ads";

import { setApplicationModal } from "../../../core/slice/application";

import { useAppDispatch } from "../../../utils/useStore";

import Pagination from "@material-ui/lab/Pagination";

import Application from "../../../core/services/application";

import { IAd } from "../../../core/models/model";
import { UserContext } from "../../../core/providers/user";
import View from "./view";

function Ads() {
  const { type, id } = useParams<{ type: string; id: string }>();

  const [data, setData] = useState<any[]>([]);

  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(1);
  const [max, setMax] = useState<number>(page);
  const [numberOfPage, setNumberOfPage] = useState<number>(page);

  const last = useRef<IAd>();

  const { user } = useContext(UserContext);

  useEffect(function () {
    const getAllAds = async function () {
      dispatch(setApplicationModal({ index: 1, props: null }));

      const number: number | null = await Application.getNumberOfPage(type);

      if (number === null) {
        setNumberOfPage(0);
      } else {
        setNumberOfPage(Math.ceil(number / 5));
      }

      const data: any = await Firestore.getAdsFromFirestore(5, type, setRef);

      setData(data);

      dispatch(setApplicationModal({ index: null, props: null }));
    };

    getAllAds();
  }, []);

  const setRef = function (doc: any): void {
    last.current = doc;
    console.log("Last document ref:", JSON.stringify(last.current));
  };

  const fetchMoreAdsFromFirstore = async function (): Promise<IAd[] | null> {
    dispatch(setApplicationModal({ index: 1, props: null }));

    const data: IAd[] | null = await Firestore.getAdsFromFirestoreWithRef(
      5,
      type,
      last,
      setRef
    );

    dispatch(setApplicationModal({ index: null, props: null }));

    return data;
  };

  const handleChangePageEvent = async function (
    event: React.ChangeEvent<unknown>,
    value: number
  ): Promise<void> {
    window.scrollTo({ top: 600, behavior: "smooth" });

    if (value <= max) {
      setPage(value);

      return;
    }

    const more: IAd[] | null = await fetchMoreAdsFromFirstore();

    if (more === null) {
      return;
    }

    const tmp = data.concat(more);

    setData(tmp);
    setPage(value);
    setMax(value);
  };

  const searchResult = function (): JSX.Element {
    return (
      <div className="ads-view-wrapper">
        <Search />
        <div className="ads-sectionn">{displayCurrentPage}</div>
        <Pagination
          count={numberOfPage}
          variant="outlined"
          shape="rounded"
          size="large"
          onChange={handleChangePageEvent}
        />
      </div>
    );
  };

  const displayCurrentPage = data
    .slice((page - 1) * 5, (page - 1) * 5 + 5)
    .map((annonce, index) => <Annonce key={index} ad={annonce} />);

  return (
    <Switch>
      <Route exact path="/home/search/:type">
        {searchResult()}
      </Route>
      <Route exact path="/home/search/:type/ads/:id">
        <View />
      </Route>
    </Switch>
  );
}

export default Ads;
